<!--
 * @Author: HZH
 * @Date: 2021-09-16 14:16:01
 * @LastEditors: HZH
 * @LastEditTime: 2021-09-18 14:20:17
 * @Description: 
-->
<template>
  <div>
    <Head></Head>
    <!-- {{$route.query.id}}
     -->
    <div class="content">
      <div class="wrapper">
        <div class="title">{{artData.title}}</div>
        <div class="detailMes">
          
        </div>
        <div class="artcontent" v-html="artData.content">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Head from './components/Head.vue'
import {get} from '../utils/request'
  export default {
    components:{
      Head
    },
    data() {
      return {
        articleItem:{},
        artData:{}
      }
    },
    methods: {
      getArtContent(){
        get('/index/article/findById',{id:this.articleItem.id}).then(res =>{
          this.artData = res.data
        })
      }
    },
    created(){
      this.articleItem = this.$route.query;
      this.getArtContent()
    }
  }
</script>

<style scoped>

</style>